import { useState } from "react";

import styles from "./Freeform.module.scss";

interface FreeformProps {
  label: string;
  onSend(content: string): void;
  isSending: boolean;
  disabled?: boolean;
}

export default function Freeform({
  label,
  onSend,
  isSending,
  disabled,
}: FreeformProps) {
  const [value, setValue] = useState("");
  const trimmed = value.trim();
  const MAX_ROWS = 6;

  const handleSubmit = () => {
    if (!trimmed) return;
    onSend(trimmed);
    setValue("");
  };

  return (
    <form
      className={styles["freeform"]}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <textarea
        name="content"
        placeholder={label}
        value={value}
        onChange={(e) => {
          const textarea = e.currentTarget;
          setValue(textarea.value);

          textarea.style.height = "auto";
          const rows = textarea.scrollHeight / textarea.clientHeight;

          if (rows <= MAX_ROWS) {
            textarea.style.height = `${textarea.scrollHeight}px`;
          } else {
            textarea.style.height = `${textarea.clientHeight * MAX_ROWS}px`;
          }
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit();
          }
        }}
        rows={1}
        disabled={disabled}
      />
      <button type="submit" disabled={!trimmed || isSending || disabled}>
        
      </button>
    </form>
  );
}
