export const FREE_BASIC_DISCOUNTED_PREMIUM = "free_basic_discounted_premium";

export const FIRST_MONTH_DISCOUNT_PROMO =
  "dropoff_incentives_first_month_half_off";
export const FIRST_MONTH_DISCOUNT_PROMO_PREMIUM =
  "dropoff_incentives_first_month_half_off_premium";

export const LAST_MONTH_FREE_PROMO = "last_month_free";
export const LAST_MONTH_FREE_PROMO_PREMIUM = "last_month_free_premium";

export const INTRO_TEST_OFFER = "intro_test_offer";
export const INTRO_TEST_OFFER_PREMIUM = "intro_test_offer_premium";

export const CANCELATION_ONE_MONTH_OFFER = "cancelation_one_month_offer";
export const CANCELATION_ONE_MONTH_OFFER_PREMIUM =
  "cancelation_one_month_offer_premium";

export const SUBSCRIPTION_RENEWAL = "subscription_renewal_v1";

export const EIGHTY_PERCENT_OFF = "eighty_percent_off";
export const EIGHTY_PERCENT_OFF_PREMIUM = "eighty_percent_off_premium";
export const FIRST_MONTH_FREE = "first_month_free";
export const FIRST_MONTH_FREE_PREMIUM = "first_month_free_premium";

export const DISPUTE_REACTIVATION = "dispute_reactivation";
export const DISPUTE_REACTIVATION_PREMIUM = "dispute_reactivation_premium";

export const ENABLE_AUTO_PAY_DISCOUNT = "enable_autopay_upsell_offer";

export const UPGRADE_PROMO_FIVE_DOLLARS = "upgrade_promo_five_dollars";
export const UPGRADE_PROMO_TEN_DOLLARS = "upgrade_promo_ten_dollars";

export type PromoName =
  | typeof FREE_BASIC_DISCOUNTED_PREMIUM
  | typeof SUBSCRIPTION_RENEWAL
  | typeof FIRST_MONTH_DISCOUNT_PROMO
  | typeof FIRST_MONTH_DISCOUNT_PROMO_PREMIUM
  | typeof LAST_MONTH_FREE_PROMO
  | typeof LAST_MONTH_FREE_PROMO_PREMIUM
  | typeof INTRO_TEST_OFFER
  | typeof INTRO_TEST_OFFER_PREMIUM
  | typeof CANCELATION_ONE_MONTH_OFFER
  | typeof CANCELATION_ONE_MONTH_OFFER_PREMIUM
  | typeof EIGHTY_PERCENT_OFF
  | typeof EIGHTY_PERCENT_OFF_PREMIUM
  | typeof FIRST_MONTH_FREE
  | typeof FIRST_MONTH_FREE_PREMIUM
  | typeof DISPUTE_REACTIVATION
  | typeof DISPUTE_REACTIVATION_PREMIUM
  | typeof UPGRADE_PROMO_FIVE_DOLLARS
  | typeof UPGRADE_PROMO_TEN_DOLLARS
  | typeof ENABLE_AUTO_PAY_DISCOUNT;

export const INFLUENCER_UTM_MEDIUM = "influencer";

export const INTRO_PROMOS = [
  FREE_BASIC_DISCOUNTED_PREMIUM,
  EIGHTY_PERCENT_OFF,
  EIGHTY_PERCENT_OFF_PREMIUM,
  FIRST_MONTH_FREE,
  FIRST_MONTH_FREE_PREMIUM,
];
