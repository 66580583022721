/* eslint-disable */
import { isClient } from "@kikoff/utils/src/general";

if (isClient) {
  (function() {
    var w=window;
    var ic=w.Intercom;
    if(typeof ic==="function") {
      ic('reattach_activator');
      ic('update',w.intercomSettings);
    } else{
      var d=document;
      var i=function() {
        i.c(arguments);
      };
      i.q=[];
      i.c=function(args) {
        i.q.push(args);
      };
      w.intercom=i;
      var l=function() {
        var s=d.createElement('script');
        s.type='text/javascript';
        s.async=true;
        s.src='https://widget.intercom.io/widget/ubvcxhef';
        var x=d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s,x);
      };
      if(d.readyState==='complete') {
        l();
      } else if(w.attachEvent) {
        w.attachEvent('onload',l);
      } else {
        w.addEventListener('load',l,false);
      }
    }
  })();

  window.Intercom?.("update");

}