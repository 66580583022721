import React, {
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import produce from "immer";

import GenericSpinner from "@kikoff/components/src/v1/animations/GenericSpinner";
import ContainerButton from "@kikoff/components/src/v1/buttons/ContainerButton";
import Card from "@kikoff/components/src/v1/cards/Card";
import Freeform from "@kikoff/components/src/v1/chat/Freeform";
import Messages, { IMessage } from "@kikoff/components/src/v1/chat/Messages";
import useUpdate from "@kikoff/hooks/src/useUpdate";
import { google, web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { promiseDelay } from "@kikoff/utils/src/general";
import { handleProtoStatus } from "@kikoff/utils/src/proto";
import { format } from "@kikoff/utils/src/string";

import BottomSheet from "@component/overlay/bottom_sheet";
import BottomSheetLayout from "@component/overlay/layout/bottom_sheet";
import KMarkdown from "@component/text/Markdown";
import { initCreditV2 } from "@feature/credit";
import { getKikoffCreditAccountMentalModelName } from "@src/constants";
import { handleURL } from "@src/kikoff_url";
import { buildOverlay, useOverlay } from "@src/overlay";

import styles from "./chat.module.scss";

type Context = keyof typeof web.public_.ChatConversation.Context;

interface ChatOverlayProps {
  context: Context;
}

const ChatOverlay = buildOverlay
  .layout(BottomSheetLayout)
  .config({ duplicateBehavior: "replace" })(function O({
  context,
}: ChatOverlayProps) {
  const overlay = useOverlay(ChatOverlay);
  const dispatch = useDispatch();

  const credit = useSelector((state) => state.credit.credit);

  const [
    { messages, prompt, actions, isSending, pausePolling, isHumanAgent },
    setChat,
  ] = useChat(context);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!credit) dispatch(initCreditV2());
    if (messages.length === 0) {
      setChat.fetchMessages().then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (loading || !isHumanAgent || pausePolling) return;

    const POLLING_INTERVAL = 1000;
    let timeoutId: NodeJS.Timeout;
    let canceled = false;
    async function poll() {
      await setChat.fetchMessages({ polling: true });
      if (canceled) return;
      timeoutId = setTimeout(poll, POLLING_INTERVAL);
    }

    poll();

    return () => {
      clearTimeout(timeoutId);
      canceled = true;
    };
  }, [loading, isHumanAgent, pausePolling]);

  return (
    <BottomSheet className={styles["chat-overlay"]}>
      <header>
        <ContainerButton
          className={styles.back}
          onClick={() => {
            overlay.removeSelf();
          }}
        >
          
        </ContainerButton>
        <div className={`${styles.title} text:regular+`}>
          Kikoff Customer Support Chat
        </div>
        <div />
      </header>
      <div className={styles["scroll-container"]}>
        <div>
          {loading && <GenericSpinner center size={24} />}
          <Messages messages={messages} />
          <Actions
            actions={actions}
            onClick={(url) => {
              if (url) handleURL(url);
              setChat.actions([]);
            }}
          />
          <Prompt
            prompt={prompt}
            onInput={(content) => {
              setChat.sendMessage(content);
            }}
          />
          <Freeform
            label="Write a message"
            onSend={(content) => {
              setChat.sendMessage(content);
            }}
            isSending={isSending}
          />
          {!loading && <PreviousMessagesLoader context={context} />}
        </div>
      </div>
    </BottomSheet>
  );
});

export default ChatOverlay;

type PreviousMessagesLoaderProps = {
  context: Context;
};

const PreviousMessagesLoader = React.memo(function ({
  context,
}: PreviousMessagesLoaderProps) {
  const [{ canLoadPrevMessages }, setChat] = useChat(context);
  const { ref: anchorRef } = useInView({
    skip: !canLoadPrevMessages,
    onChange: async (inView) => {
      if (!inView) return;
      await setChat.fetchMessages();
    },
  });

  return <div ref={anchorRef} className={styles["fetch-more-messages"]} />;
});

interface MessageFeedbackProps {
  onRate(rating: -1 | 1): void;
  sent: boolean;
}

function MessageFeedback({ onRate, sent }: MessageFeedbackProps) {
  return (
    <motion.div className={`${styles.feedback} text:mini color:moderate p-0.5`}>
      {sent ? (
        "Thanks for your feedback"
      ) : (
        <div className="text:+">
          <ContainerButton
            onClick={() => {
              onRate(1);
            }}
            inline
            className="mr-1.5"
          >
            👍 Helpful
          </ContainerButton>
          <ContainerButton
            inline
            onClick={() => {
              onRate(-1);
            }}
          >
            👎 Not helpful
          </ContainerButton>
        </div>
      )}
    </motion.div>
  );
}

interface ActionsProps {
  actions: ChatState["actions"];
  onClick(url: string): void;
}

function Actions({ actions, onClick }: ActionsProps) {
  return (
    <div className={styles["actions"]}>
      <AnimatePresence>
        {actions.length > 0 && (
          <motion.div
            initial={{ height: 0, opacity: 0, x: "50%" }}
            animate={{ height: null, opacity: 1, x: 0, margin: "8px 0 24px" }}
            exit={{ height: 0, opacity: 0, x: "50%", margin: 0 }}
            className={styles.container}
          >
            {actions.map(({ label, url }) => (
              <Card
                outline
                className={styles.action}
                as={ContainerButton}
                onClick={() => {
                  onClick(url);
                }}
              >
                <div className="text:small mb-0.5"></div>
                <div className="text:mini+">{label}</div>
              </Card>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface PromptProps {
  prompt: IPrompt;
  onInput(input: IPrompt["options"][number]): void;
}

function Prompt({ prompt, onInput }: PromptProps) {
  return (
    <div className={styles["prompt"]}>
      <AnimatePresence>
        {prompt && (
          <motion.div
            initial={{ height: 0, opacity: -0.5 }}
            animate={{ height: null, opacity: 1 }}
            exit={{ height: 0, opacity: -0.5 }}
          >
            {(() => {
              if (prompt.type === "multiple-choice")
                return (
                  <div className={styles["option-list"]}>
                    {prompt.options.map((option, i) => (
                      <ContainerButton
                        key={i}
                        onClick={() => {
                          onInput(option);
                        }}
                      >
                        {option}
                      </ContainerButton>
                    ))}
                  </div>
                );
            })()}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface MultipleChoice {
  type: "multiple-choice";
  options: string[];
}

type IPrompt = MultipleChoice;

enum MessageContent {
  TYPING,
  START,
}

const defaultChatState = {
  messages: [] as IMessage[],
  canLoadPrevMessages: false,
  prompt: null as IPrompt,
  actions: [] as web.public_.SendMessageWithBufferResponse["actions"],
  isSending: false,
  pausePolling: false,
  isHumanAgent: false,
};
type ChatState = typeof defaultChatState;

const ChatContext = React.createContext(
  null as useState.Result<Partial<Record<Context, typeof defaultChatState>>>
);

export function ChatProvider({ children }) {
  const [chat, setChat] = useState({}) as React.ContextType<typeof ChatContext>;

  return (
    <ChatContext.Provider value={[chat, setChat]}>
      {children}
    </ChatContext.Provider>
  );
}

const useChat = (context: Context) => {
  const [chatByContext, setChatByContext] = useContext(ChatContext);
  const pendingMessageId = useRef(1);
  const sentRatings = useRef(new Set()).current;
  const update = useUpdate();
  const markRated = (messageId: string) => {
    sentRatings.add(messageId);
    update();
  };

  const typingPredicate = ({ sender, content }) =>
    sender === "bot" && content === MessageContent.TYPING;

  if (!(context in chatByContext)) chatByContext[context] = defaultChatState;

  const chatRef = useRef(chatByContext[context]);
  chatRef.current = chatByContext[context];

  const setChat = Object.assign(
    (action: SetStateAction<typeof defaultChatState>) => {
      setChatByContext((prev) => ({
        ...prev,
        [context]:
          typeof action === "function" ? action(prev[context]) : action,
      }));
    },
    {
      fetchMessages({ polling }: { polling?: boolean } = { polling: false }) {
        const MSG_FETCH_LIMIT = 30;
        const paginationParams = polling
          ? { afterUuid: chatRef.current.messages?.at(-1)?.id }
          : { beforeUuid: chatRef.current.messages?.at(0)?.id };

        return webRPC.Chat.fetchMessages({
          conversation: {
            context: web.public_.ChatConversation.Context[context],
          },
          limit: MSG_FETCH_LIMIT,
          ...paginationParams,
        }).then(
          handleProtoStatus({
            SUCCESS(data) {
              if (polling && chatRef.current.pausePolling) return;

              const { Sender } = web.public_.ChatMessage;
              if (data.messages.length > 0) {
                setChat((prev) => {
                  const isFirstFetch = prev.messages.length === 0;
                  const isHumanAgent =
                    polling || isFirstFetch
                      ? data.messages.at(-1)?.humanTakeover
                      : prev.isHumanAgent;

                  const newMessages = data.messages.map(
                    ({ content, sender, sentAt, uuid }) => ({
                      sender: {
                        [Sender.KIKOFF]: "bot",
                        [Sender.USER]: "user",
                      }[sender],
                      sentAt: formatMsgDate(sentAt),
                      content: <KMarkdown>{content}</KMarkdown>,
                      textContent: content,
                      id: uuid,
                      actions: polling ? FeedbackAction : null,
                    })
                  );

                  return {
                    ...prev,
                    isHumanAgent,
                    messages: polling
                      ? [...prev.messages, ...newMessages]
                      : [...newMessages, ...prev.messages],
                  };
                });
              } else if (chatRef.current.messages.length === 0 && !polling) {
                const initialOptions = {
                  CREDIT_COACH: [
                    "What are the five credit factors?",
                    "How can I dispute incorrect information on my credit report?",
                    "What are some ways to reduce my debt?",
                  ],
                  SUPPORT: [
                    `How do I make a payment for my ${getKikoffCreditAccountMentalModelName()}?`,
                    "How can I use my line of credit?",
                    "How do I turn on autopay?",
                  ],
                }[context];
                if (initialOptions)
                  setChat.prompt({
                    options: initialOptions,
                    type: "multiple-choice",
                  });
              }

              setChat((prev) => {
                if (polling) return prev;

                return {
                  ...prev,
                  canLoadPrevMessages: data.messages.length >= MSG_FETCH_LIMIT,
                };
              });
            },
            _DEFAULT() {
              throw new Error("Failed to fetch message history.");
            },
          })
        );
      },

      startTyping() {
        setChat((prev) => {
          if (prev.messages.some(typingPredicate)) return prev;
          return {
            ...prev,
            messages: [
              ...prev.messages,
              {
                sender: "bot",
                content: MessageContent.TYPING,
              },
            ],
          };
        });
      },

      stopTyping() {
        setChat((prev) => {
          const newMessages = prev.messages.filter(
            ({ content }) => content !== MessageContent.TYPING
          );
          return { ...prev, messages: newMessages };
        });
      },

      pushMessage(message: IMessage) {
        setChat((prev) =>
          produce(prev, (draft) => {
            if (message.sender === "bot") {
              const typingMessage = draft.messages.find(typingPredicate);
              if (typingMessage) {
                Object.assign(typingMessage, message);
                return;
              }
            }

            draft.messages.push(message);
          })
        );
      },

      async sendMessage(content: string | MessageContent.START) {
        setChat((prev) => ({ ...prev, isSending: true, pausePolling: true }));

        const pendingId =
          content !== MessageContent.START &&
          `pending:${pendingMessageId.current++}`;
        if (content !== MessageContent.START) {
          setChat.pushMessage({
            sender: "user",
            content,
            id: pendingId,
            sentAt: formatMsgDate(new Date().toISOString()),
          });
          setChat.prompt(null);
        }

        const response = (async (): Promise<
          Partial<Omit<ChatState, "messages">> & {
            messages?: OmitFromUnion<IMessage, "sender">[];
          }
        > => {
          const data: web.public_.SendMessageResponse = await webRPC.Chat.sendMessage(
            {
              conversation: {
                context: web.public_.ChatConversation.Context[context],
              },
              // `content` can be MessageContent.START
              message: typeof content === "string" ? content : null,
            }
          )
            .then(
              handleProtoStatus({
                _DEFAULT(data) {
                  return data;
                },
              })
            )
            .catch(() => false)
            .finally(() => {
              setChat((prev) => ({ ...prev, isSending: false }));
            });

          if (!data)
            return {
              messages: [
                {
                  content:
                    "Uh oh, something went wrong. Please try again or come back later.",
                },
              ],
            };

          if (pendingId)
            setChat((prev) =>
              produce(prev, (draft) => {
                draft.messages.find(({ id }) => id === pendingId).id =
                  data.messageUuid;
              })
            );

          setChat((prev) => {
            if (data.humanTakeover === prev.isHumanAgent) return prev;
            return { ...prev, isHumanAgent: data.humanTakeover };
          });

          if (!data.responseContent) return { messages: [] };

          return {
            messages: [
              {
                content: <KMarkdown>{data.responseContent}</KMarkdown>,
                actions: FeedbackAction,
                textContent: data.responseContent,
                id: data.responseUuid,
              },
            ],
          };
        })();

        if (!chatRef.current.isHumanAgent) {
          await promiseDelay(500);
          setChat.startTyping();
          await promiseDelay(1000);
        }

        const {
          prompt,
          messages: [first, ...rest],
        } = await response;

        if (first) {
          setChat.pushMessage({
            sender: "bot",
            ...first,
          });
        } else {
          setChat.stopTyping();
        }

        for (const message of rest) {
          setChat.startTyping();
          await promiseDelay(2000);
          setChat.pushMessage({
            sender: "bot",
            ...message,
          });
        }

        await promiseDelay(700);

        setChat.prompt(prompt);
        setChat((prev) => ({ ...prev, pausePolling: false }));
      },

      rateMessage(messageId: string, rating: 1 | -1) {
        markRated(messageId);

        const { Rating } = web.public_.RateMessageRequest;

        webRPC.Chat.rateMessage({
          messageUuid: messageId,
          rating: { 1: Rating.GOOD, [-1]: Rating.BAD }[rating],
          v2: true,
        });
      },

      actions(actions: ChatState["actions"]) {
        setChat((prev) => ({ ...prev, actions }));
      },

      prompt(prompt: IPrompt) {
        setChat((prev) => ({ ...prev, prompt }));
      },
    }
  );

  const FeedbackAction = ({ id }: { id: string }) => (
    <MessageFeedback
      sent={sentRatings.has(id)}
      onRate={(rating) => setChat.rateMessage(id, rating)}
    />
  );

  return [chatRef.current, setChat] as const;
};

function formatMsgDate(rawDate: google.protobuf.ITimestamp | string): string {
  const msgDate = new Date(
    typeof rawDate === "string" ? rawDate : rawDate.seconds * 1000
  );
  const now = new Date();

  const date =
    msgDate.getDate() === now.getDate()
      ? "Today"
      : (() => {
          const includeYear = msgDate.getFullYear() !== now.getFullYear();
          const yearPattern = includeYear ? ", yyyy" : "";
          return format.date(msgDate, `Mmm d${yearPattern}`);
        })();

  const time = format.time(msgDate, "12h:mm apm");

  return `${date}, ${time}`;
}
