import { Buffer } from "buffer";

import axios from "axios";
import Cookie from "js-cookie";
import { Method, Service } from "protobufjs/light";
import { RecordTuple, Tuple } from "record-tuple";

import { DEBUG } from "@kikoff/client-utils/src/general";
import { hasLength, isMappable, isSlicable } from "@kikoff/utils/src/array";
import { API_ORIGIN } from "@kikoff/utils/src/env";
import EventEmitter from "@kikoff/utils/src/EventEmitter";
import { memo } from "@kikoff/utils/src/function";
import { isClient, promiseDelay } from "@kikoff/utils/src/general";
import { camelToKebab } from "@kikoff/utils/src/string";

import { StubHandler } from "./dev/stubs/utils";
import { kikoff, views, web } from "./protos";
import { ServiceApi, ViewsApi, ViewsCommonApi } from "./types";

let KIKOFF_CLIENT: string;

export const userLeadKey = "X-User-Lead";

function addUserLeadHeadersToRequest(data, headers) {
  if (isClient) {
    const userLead = Cookie.get(userLeadKey);
    if (userLead) headers[userLeadKey] = userLead;
  }

  return data;
}

// Rpc doesn't have access to mobileFlavor which is scoped to apps/main
export const configureRpc = ({ version, release, mobileFlavor }) => {
  KIKOFF_CLIENT = Buffer.from(
    JSON.stringify([version, release, mobileFlavor])
  ).toString("base64");
};

export const httpClient = axios.create({
  baseURL: API_ORIGIN,
  withCredentials: true,
  responseType: "arraybuffer",
  headers: {
    ...(process.env.NODE_ENV === "test" && {
      "X-Time-Travel": new Date().toISOString(),
    }),
    "Content-Type": "application/octet-stream",
    "Cache-Control": "no-cache",
  },
  // `transformRequest` allows changes to the request data before it is sent to the server
  // This is only applicable for request methods 'PUT', 'POST', 'PATCH' and 'DELETE'
  // The last function in the array must return a string or an instance of Buffer, ArrayBuffer,
  // FormData or Stream
  // You may modify the headers object.
  transformRequest: [
    (data, headers) => {
      headers["X-Kikoff-Frontend-Client"] = KIKOFF_CLIENT;
      return data;
    },
    addUserLeadHeadersToRequest,
    Buffer.from,
  ],
  transformResponse: Buffer.from,
});

const rpcLog = (
  serviceName: string,
  methodName: string,
  { stubbed = false, cacheHit = false } = {}
) => (...data) => {
  // eslint-disable-next-line no-console
  return console.log(
    `${stubbed ? "%c[STUB]%c " : ""}${
      cacheHit ? "[CACHE-HIT]" : ""
    }%cRPC%c[%c${serviceName}%c] -> %c${methodName}%c`,
    ...(stubbed ? ["background: blue; color: white", ""] : []),
    "color: #0a0; font-weight: bold",
    "color: grey",
    "color: #5af; font-weight: bold",
    "color: grey",
    "color: orange; font-weight: bold",
    "",
    ...data
  );
};

const stubs = DEBUG && import("./dev/stubs");

const cache = new Map<
  Tuple<[endpoint: string, request: Record<string, any>]>,
  Promise<any>
>();

export const webRPC = RPCHandler<ServiceApi>("/api/v1/", [
  ...Object.entries(web.public_),
  ...Object.entries(kikoff.api.mfa),
  ...Object.entries(kikoff.api.hello_privacy),
  ...Object.entries(kikoff.api.testing.experiments),
]);

export const legacyViewsRPC = RPCHandler<ViewsApi>("/views/v1/", [
  ...Object.entries(views.public_),
]);

export const legacyViewsCommonRPC = RPCHandler<ViewsCommonApi>("/views/v1/", [
  ...Object.entries(views.common),
]);

type RPCApi = ServiceApi | ViewsApi;

let idCounter = 0;

function RPCHandler<Api = RPCApi>(apiEndpoint: string, protos) {
  return (Object.fromEntries(
    ((protos.filter(([, value]) => value instanceof Service) as unknown) as [
      keyof ServiceApi,
      Service
    ][]).map(([serviceName, service]) => [
      serviceName,
      service.create(async (method: Method, requestData, callback) => {
        const camelMethodName = `${method.name[0].toLowerCase()}${method.name.slice(
          1
        )}`;

        const stubList = await (async () => {
          if (!stubs) return;
          const list: StubHandler<any>[] = [];
          for (const [key, stub] of Object.entries(await stubs)) {
            if (stub[serviceName]?.[camelMethodName])
              list.push(stub[serviceName]?.[camelMethodName]);
          }
          return list;
        })();
        const isStubbed = stubList?.length > 0;

        const requestType = method.resolvedRequestType;
        const responseType = method.resolvedResponseType;

        const hasField = <T extends typeof responseType, F extends string>(
          type: T,
          field: F
        ): type is T & { [key in F]: Record<string, number> } => field in type;

        const serviceRouteNamespace = camelToKebab(serviceName).replace(
          /-service$/,
          ""
        );
        const endpoint = `${apiEndpoint}${serviceRouteNamespace}/${camelToKebab(
          method.name
        )}`;

        const decodedRequest = requestType.decode(requestData);
        const ref = RecordTuple.deep([
          endpoint,
          // Limit lengths of arrays in payload to prevent RecordTuple from
          // creating incredibly deep nested maps
          (function limit(data = decodedRequest) {
            // Use schema checking methods since TypedArrays aren't Arrays,
            // Array.isArray(TypedArray) will be false
            if (hasLength(data) && isSlicable(data) && isMappable(data))
              return (data.length > 100
                ? [...data.slice(0, 100), `+ ${data.length - 100} more`]
                : data
              ).map(limit);

            if (typeof data === "object")
              return Object.fromEntries(
                Object.entries(data).map(([k, v]) => [k, limit(v)])
              );

            return data;
          })(),
        ] as const);

        const id = idCounter++;
        rpcEvents.emit
          .cacheUntil(promiseDelay(2000))
          .ifListening("request", () => ({
            id,
            time: Date.now(),
            stubbed: isStubbed,
            service: serviceName,
            method: method.name,
            request: requestType.toObject(decodedRequest, {
              enums: String,
            }),
          }));

        const log = rpcLog(serviceName, method.name, {
          stubbed: isStubbed,
          cacheHit: cache.has(ref),
        });

        let resolve;
        let reject;
        let promise: Promise<any>;
        if (DEBUG) {
          promise = new Promise((res, rej) => {
            resolve = res;
            reject = rej;
          });

          log(
            "(",
            requestType.toObject(decodedRequest, {
              enums: String,
            }),
            "): ",
            Promise.resolve(promise)
          );
        }

        const makeRequest = (req?) =>
          httpClient
            .post(
              endpoint,
              req ? requestType.encode(req).finish() : requestData
            )
            .then(({ data }) => data);

        if (!cache.has(ref))
          cache.set(
            ref,
            Promise.all([
              (async () => {
                if (!stubList?.length) return makeRequest();
                const res = await stubList.reduce<any>(
                  (acc, curr) => (req) =>
                    curr({
                      req: req || decodedRequest,
                      makeRequest: (_req = req) => acc(_req as any),
                    }),
                  (req) =>
                    makeRequest(req).then((data) => responseType.decode(data))
                )();

                if (hasField(responseType, "Status")) {
                  res.status ??= res.error
                    ? responseType.Status.FAILED
                    : responseType.Status.SUCCESS;
                }

                return res;
              })(),
              stubList?.length > 0 && promiseDelay(1000),
            ]).then(([res]) => res)
          );

        cache.get(ref).finally(() => {
          cache.delete(ref);
        });

        cache
          .get(ref)
          .then((data) => {
            const decodeResponse = memo(() =>
              data._isBuffer ? responseType.decode(data) : data
            );

            rpcEvents.emit
              .cacheUntil(promiseDelay(2000))
              .ifListening("response", () => ({
                id,
                time: Date.now(),
                response: responseType.toObject(decodeResponse(), {
                  defaults: true,
                  enums: String,
                }),
              }));

            if (DEBUG)
              resolve(
                responseType.toObject(decodeResponse(), {
                  defaults: true,
                  enums: String,
                })
              );
            callback(
              null,
              data._isBuffer ? data : responseType.encode(data).finish()
            );
          })
          .catch((err) => {
            if (DEBUG) {
              reject(err);
              promise.catch(() => {});
            }
            if (err.message === "Network Error") {
              rpcEvents.emit("networkError", {
                err,
                method: webRPC[serviceName][camelMethodName],
              });
            }
            callback(err);
          });
      }),
    ])
  ) as unknown) as Api;
}

export const rpcEvents = new EventEmitter<
  [
    [
      "networkError",
      {
        err: Error;
        method: (request: any) => Promise<any>;
      }
    ],
    [
      "request",
      {
        id: number;
        time: number;
        service: string;
        method: string;
        stubbed: boolean;
        request: any;
      }
    ],
    ["response", { id: number; time: number; response: any }]
  ]
>();
