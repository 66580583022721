import React from "react";

import Lottie from "@kikoff/components/src/v1/animations/Lottie";
import { combineClasses } from "@kikoff/utils/src/string";

import kikoffLoaderAnimationData from "./kikoff-loader.json";

import styles from "./loading_indicators.module.scss";

interface GenericSpinnerProps {
  color?: string;
  size?: React.CSSProperties["fontSize"];
  center?: true;
  style?: React.CSSProperties;
}
// @deprecated use packages/components/src/v1/animations/GenericSpinner.tsx instead
export const GenericSpinner: React.FC<GenericSpinnerProps> = ({
  color,
  size = "50px",
  center,
  style,
}) => (
  <div
    className={combineClasses(
      styles["generic-spinner"],
      center && styles.center
    )}
    style={{ fontSize: size, ...style }}
  >
    <svg width="1em" height="1em">
      <circle
        cx="0.5em"
        cy="0.5em"
        r="0.4em"
        fill="none"
        strokeWidth="0.1em"
        {...(color && { style: { stroke: color } })}
      />
    </svg>
  </div>
);

interface KikoffLoaderProps {
  size?: number;
}

export const KikoffLoader: React.FC<KikoffLoaderProps> = ({ size = 50 }) => {
  return (
    <Lottie
      play
      loop
      style={{ width: size, height: size }}
      animationData={kikoffLoaderAnimationData}
      rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
    />
  );
};

interface MessageLoaderProps {
  Spinner?: string | React.FC;
  main?: React.ReactNode;
  sub?: React.ReactNode;
  className?: string;
  loading?: boolean;
  style?: React.CSSProperties;
}

export const MessageLoader: React.FC<MessageLoaderProps> = ({
  Spinner = GenericSpinner,
  main,
  sub,
  className,
  loading = true,
  style,
  ...props
}) => {
  return (
    <div
      className={combineClasses(styles["message-loader"], className)}
      style={style}
    >
      <header>
        {main}
        {sub && <span>{sub}</span>}
      </header>
      {loading && React.createElement(Spinner)}
    </div>
  );
};
