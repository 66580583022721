import createExperiment from "./createExperiment";

export default createExperiment({
  defaultAttributes: {
    version: 1,
  },
  variants: {
    v1: {
      routes: {
        "/onboarding": "/onboarding/v1",
        "/onboarding/info": "/onboarding/v1/info",
        "/onboarding/verify-email": "/onboarding/v1/verify-email",
        "/onboarding/flagged": "/onboarding/flagged",
        "/onboarding/credit-freeze": "/onboarding/credit-freeze",
        "/onboarding/duplicate": "/onboarding/duplicate",
        "/onboarding/questions/:q": "/onboarding/questions/:q",
        "/onboarding/manual-review": "/onboarding/manual-review",
        "/onboarding/persona-passable": "/onboarding/persona-passable",
        "/onboarding/education": "/onboarding/education",
        "/onboarding/instatouch": "/onboarding/instatouch",
        "/onboarding/review-info": "/onboarding/review-info",
      },
      redirects: {
        "/onboarding/:step": "/onboarding",
        "/easy": "/",
        "/educate": "/",
        "/simple": "/",
      },
    },
    v2: {
      routes: {
        "/onboarding": "/onboarding/v2",
        "/onboarding/flagged": "/onboarding/flagged",
        "/onboarding/duplicate": "/onboarding/duplicate",
        "/onboarding/:step": "/onboarding/v2/:step",
        "/onboarding/credit-freeze": "/onboarding/credit-freeze",
        "/onboarding/questions/:q": "/onboarding/questions/:q",
        "/onboarding/manual-review": "/onboarding/manual-review",
        "/onboarding/persona-passable": "/onboarding/persona-passable",
      },
      redirects: {
        "/onboarding/info": "/onboarding",
        "/easy": "/",
        "/educate": "/",
        "/simple": "/",
      },
      attributes: {
        version: 2,
      },
    },
  },
}).weigh({
  v1: 100,
  v2: 0,
});
