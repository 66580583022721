import React, { useMemo } from "react";

import { textColorForBackground } from "@kikoff/client-utils/src/dom";
import { combineClasses } from "@kikoff/utils/src/string";

import styles from "./Chip.module.scss";

interface ChipProps {
  className?: string;
  text: string;
  colorHex?: React.CSSProperties["color"];
  textColor?: React.CSSProperties["color"];
  raised?: boolean;
  inline?: boolean;
}

export default function Chip({
  className,
  text,
  colorHex: background = "#ffffff",
  textColor,
  raised = false,
  inline = false,
}: ChipProps) {
  const color = useMemo(() => textColor || textColorForBackground(background), [
    background,
    textColor,
  ]);

  return (
    <div
      className={combineClasses(styles.chip, className, "text:eyebrow-2")}
      style={{
        background,
        color,
        boxShadow: raised && "0 0 4px 0 #0002",
        display: inline ? "inline-flex" : "flex",
      }}
    >
      {text}
    </div>
  );
}

interface ChipV2Props {
  className?: string;
  color: React.CSSProperties["color"];
  children: React.ReactNode;
  raised?: boolean;
  inline?: boolean;
  size?: "large" | "regular" | "small" | "mini" | "micro";
  icon?: React.ReactNode;
  variant?: "round" | "rounded-square";
  compact?: boolean;
  uniform?: boolean;
}

export function ChipV2({
  className,
  children,
  color,
  raised = false,
  inline = false,
  icon,
  size = "small",
  compact = false,
  variant = "round",
  uniform = false,
}: ChipV2Props) {
  return (
    <div
      className={combineClasses(
        styles.chip2,
        className,
        compact && styles.compact,
        `text:${size}+`,
        styles[`variant_${variant}`],
        uniform && styles.uniform
      )}
      style={{
        color,
        boxShadow: raised && "0 0 4px 0 #0002",
        display: inline ? "inline-flex" : "flex",
      }}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      {children}
    </div>
  );
}
